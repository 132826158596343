<template>
  <Container :visible="visible" class="select-address" noConfirm>
    <Row label="运输方式" style="margin-bottom: 12px">
      <Picker
        v-model="type" single 
        :defaultLabel="type"
        labelString="label"
        valueString="value"
        :data="tracModeList" 
      />
    </Row>
    <Form ref="form" :rows="rows" v-model="formData" class="form" />
    <Button size="large" class="submit" @click="onSubmit">保存</Button>
  </Container>
</template>

<script>
import { addAddress } from '@/api/address';
import { fetchDictList } from '@/api/common';
import {getDealerId} from '@/utils/user';
import Row from '../row';
import Picker from '../picker';
import Container from "../container";
import Form from '@/components/common/form';
import Button from '@/components/common/button/CustomButton'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    tranMode: {
      type: String,
      default: ''
    }
  },
  components: {
    Row,
    Form,
    Picker,
    Button,
    Container,
  },
  data() {
    return {
      type: '',
      tracModeList: [],
      formData: {},
    }
  },
  
  async activated() {
    this.formData = {}
    const res = await fetchDictList({type: 'eb_tran_mode'});
    this.tracModeList = res.data.map(item => {
      if (this.tranMode === item.dictValue) {
        this.type = item.dictLabel
      }
      return {
        value: item.dictValue,
        label: item.dictLabel,
      }
    });
  },
  computed: {
    cityList() {
      return [];
    },
    areaRows() {
      return [
      {
        key: "provinceCode",
        label: "所属省份",
        type: "picker",
        dictType: "province",
        valueString: "id",
        labelString: "name",
        required: true, 
        handleChange: () => {
          this.$refs.form.$refs.cityCode[0].fetchCity(
            this.formData.provinceCode
          );
          this.$refs.form.$refs.cityCode[0].reset();
          this.$refs.form.$refs.areaCode[0].reset();
        },
      },
      {
        key: "cityCode",
        label: "所属市级",
        type: "picker",
        valueString: "id",
        labelString: "name",
        required: true, 
        handleChange: () => {
          // this.$refs.form.$refs.areaCode[0].fetchRegion(
          //   this.formData.cityCode
          // );
          this.$refs.form.$refs.areaCode[0].fetchRegionByDealer(
            this.formData.cityCode
          );
          this.$refs.form.$refs.areaCode[0].reset();
        },
      },
      {
        key: "areaCode",
        label: "所属区县",
        type: "picker",
        valueString: "id",
        labelString: "name",
        required: true, 
        gap: true,
        handleChange: () => {
        },
      },]
    },
    rows() {
      if (this.type === '10') {
        return [
          { label: '收货单位', required: true, key: 'company', type: 'input' },
          { label: '收货人姓名', required: true, key: 'tranConsignee', type: 'input' },
          { label: '收货人电话', required: true, gap: true, key: 'tranTel', type: 'input' },
          { label: '收货地址', key: 'tranAddress', gap: true, type: 'input' },
          { label: '到局', required: true,  key: 'tranAdvent', type: 'input' },
          { label: '到站', required: true,  key: 'tranArrival', type: 'input', gap: true },
          { label: '小票邮寄地址', key: 'tranBillAddress', gap: true, type: 'input' },
          { label: '小票收件人', required: true, key: 'tranBillConsignee', type: 'input' },
          { label: '邮编', required: true, gap: true, key: 'code', type: 'input' },
        ]
      } else {
        return [
          ...this.areaRows,
          { label: '收货地址', key: 'tranAddress', gap: true, type: 'input' },
          { label: '收货人姓名', required: true, key: 'tranConsignee', type: 'input' },
          { label: '收货人电话', required: true, gap: true, key: 'tranTel', type: 'input' },
        ]
      }
    }
  },
  methods: {
    async onSubmit() {
      if (this.$refs.form.validate()) {
        if(this.formData.tranTel.length !== 11) {
          return this.$toast('请输入正确的收件人电话');
        }

        const params = {
          salesId: getDealerId(),
          tranType: this.type,
          ...this.formData,
        };
        await addAddress(params);
        this.$router.back()
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.select-address {
  .form {
    padding-bottom: 64px;
  }
  .submit {
    position: fixed;
    bottom: 0;
    left: 0;
  }
}
</style>
