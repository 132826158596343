<template>
  <div>
    <Boat visible :tranMode="tranMode" />
  </div>
</template>

<script>
import Boat from '@/components/select/address/boat';
export default {
  components: {
    Boat
  },
  data() {
    return {
      tranMode: ''
    }
  },
  activated() {
    this.tranMode = this.$route.query.tranMode
  }
}
</script>